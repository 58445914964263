import React from 'react'

import './index.scss'

import Layout from '../components/layout'
import SEO from '../components/seo'

import imageFace from '../images/face.jpg'
import imageDice from '../images/dice.jpg'
import imageLearning from '../images/learning.jpg'
import imageMichel from '../images/michel.jpg'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home - Michel Weststrate"
      description="Michel Weststrate: Trainer, speaker and open source developer. Currently hacking at Facebook. Author of MobX, MobX-State-Tree, Immer and
          a plethora of smaller packages. On a quest to make programming as
          natural as possible. React, JavaScript and TypeScript fan."
      keywords={[`Michel Weststrate`, `mobx`, `immer`, `typescript`]}
    />
    <section className="center">
      <center>
        <img src={imageFace} alt="Michel Westrate" className="rounded" />
      </center>
      <h1>Michel Weststrate</h1>
      <p>
        <em style={{ color: '#666' }}>
          Trainer, speaker and above all open source
          developer.
          Currently hacking at Facebook on <a href="https://fbflipper.com" target="_blank">Flipper</a>. Author of MobX, MobX-State-Tree, Immer and
          a plethora of smaller packages. On a quest to make programming as
          natural as possible. React, JavaScript and TypeScript fan.
        </em>
      </p>
    </section>

    <section className="left">
      <img alt="Michel presenting" src={imageMichel} />
      <h2>About michel.codes</h2>
      <p>
        <em>michel.codes</em> is the personal company of Michel Weststrate,
        which has two goals:
      </p>

      <p>
        1. Provide professional services such as speaking at{' '}
        <a href="/talks">conferences</a>, giving{' '}
        <a href="/workshops">workshops</a> and providing micro-consultancy.
      </p>
      <p>
        2. Sustain popular open source projects like MobX, immer and
        mobx-state-tree through <a href="#sponsors">sponsoring</a> and services.
      </p>
      <p>
        Feel free to <a href="mailto:info@michel.codes">reach out!</a>
      </p>
    </section>

    <section className="center">
      <h2>Open source maintenance</h2>
      Some of Michel's sophisticated libraries are used by the largest companies
      in the world, such as AWS, Microsoft, SAP, Lyft and DICE. The funds needed
      to maintain and evolve these projects are coming from sponsorships through
      the <a href="https://opencollective.com/mobx">MobX open collective</a> and
      profits of michel.codes.
      <br />
      <br />
      <div className="projects">
        <a href="https://mobx.js.org/">
          <img
            src={require('../images/mobx.png')}
            align="center"
            width="100"
            title="MobX"
            alt="MobX"
          />
        </a>
        <a href="https://github.com/mobxjs/mobx-state-tree">
          <img
            src={require('../images/mobx-state-tree-logo-gradient.png')}
            align="center"
            width="100"
            title="mobx-state-tree"
            alt="mobx-state-tree"
          />
        </a>
        <a href="https://github.com/mweststrate/immer">
          <img
            src={require('../images/immer-logo.png')}
            align="center"
            width="100"
            title="immer"
            alt="immer"
          />
        </a>
      </div>
      <p>
        <img
          alt="A team of DICE employees at React Europe 2018, wearing
        t-shirts describing their favorite stack: React, MobX, TypeScript!"
          src={imageDice}
          style={{ width: '100%', height: 'initial', maxWidth: 'initial' }}
        />
        <i>
          <small>
            Image: A team of DICE employees at React Europe 2018, wearing
            t-shirts describing their favorite stack: React, MobX, TypeScript!
          </small>
        </i>
      </p>
    </section>

    <section className="right learning">
      <h2>Learning material</h2>
      <p>
        A lot of Michel's learning material is available for free, so make sure
        to check out the <a href="/blogs">blogs</a> and{' '}
        <a href="/talks">talks</a> section. Beyond that, Michel recorded two
        full egghead.io tutorials which can be watched for free!
      </p>
      <div className="course">
        <div className="img-container">
          <img
            alt="Manage Complex State in React Apps with MobX"
            src={require('../images/EGH_Mobx_Final.png')}
          />
        </div>
        <div className="course-copy">
          <a href="https://egghead.io/courses/manage-complex-state-in-react-apps-with-mobx">
            Manage Complex State in React Apps with MobX
          </a>
        </div>
      </div>
      <div className="course">
        <div className="img-container">
          <img
            alt="Manage Application State with Mobx-state-tree"
            src={require('../images/EGH_MobxStateTree.png')}
          />
        </div>
        <div className="course-copy">
          <a href="https://egghead.io/courses/manage-application-state-with-mobx-state-tree">
            Manage Application State with Mobx-state-tree
          </a>
        </div>
      </div>
      <div className="course">
        <div className="img-container">
          <img
            alt="MobX Quick Start Guide"
            src={require('../images/book.jpg')}
          />
        </div>
        <div className="course-copy">
          ...or, check out the{' '}
          <a href="https://books.google.nl/books?id=ALFmDwAAQBAJ&pg=PP1&lpg=PP1&dq=michel+weststrate+mobx+quick+start+guide:+supercharge+the+client+state+in+your+react+apps+with+mobx&source=bl&ots=D460fxti0F&sig=ivDGTxsPNwlOjLHrpKF1nweZFl8&hl=nl&sa=X&ved=2ahUKEwiwl8XO--ncAhWPmbQKHWOYBqIQ6AEwAnoECAkQAQ#v=onepage&q=michel%20weststrate%20mobx%20quick%20start%20guide%3A%20supercharge%20the%20client%20state%20in%20your%20react%20apps%20with%20mobx&f=false">
            MobX book
          </a>{' '}
          if you want a deep dive into MobX and Mobx-state-tree!
        </div>
      </div>
    </section>

    <section className="right" style={{ textAlign: 'left' }}>
      <img alt="Michel drawing on a whiteboard" src={imageLearning} />
      <h2>Expertise</h2>
      Michel has been full-stack developer for over a decade, touching code
      bases in Delphi, C#, Java, JavaScript and TypeScript. As one of the first
      employees of the start-up{' '}
      <a target="_blank" rel="noopener noreferrer" href="http://www.mendix.com">
        Mendix
      </a>
      , he has worked in many different roles, in many different teams and on
      many different stacks. Until its recent acquisition by Siemens. Beyond
      that, Michel is well known in the open source communities of React,
      TypeScript and JavaScript. As speaker, blogger, trainer, but foremost
      maintainer. His packages are downloaded several million times a month.
    </section>
    <section className="center">
      <h2>Testimonials</h2>
      <p>
        Michel is brillant, humble, kind, thoughtful and an absolute pleasure to
        work with. During our 80 hour micro consulting engagement, we've all
        learned so much from him. Michel is able to quickly understand business
        problems and how they present in code. Michel has an amazing knack for
        improving the developer experience. He can envision patterns and clean
        interfaces early. In short, Michel is a fantastic person and I am lucky
        to have been able to work alongside him.
        <br />
        <br />
        <small style={{ textAlign: 'right' }}>
          Matt Ruby. Principal Software Engineer at Bluestem Brands, Inc.
        </small>
      </p>
      <img
        width="450"
        style={{ padding: 0 }}
        src={require('../images/genius.png')}
        alt="More people should notice this genius in the shadows"
      />
      <img
        width="450"
        style={{ padding: 0 }}
        src={require('../images/beast.png')}
        alt="This dude is a beast when it comes to pushing out amazing open source projects. He's extremely friendly to interact with even if you're a total stranger, and he's an incredible talented dev."
      />
    </section>

    <section className="center" id="sponsors">
      <h2>Sponsors</h2>
      <p>
        Want to be come a sponsor? Consider supporting MobX and related projects
        through <a href="https://opencollective.com/mobx">open collective</a>.
      </p>
      <p>
        The following companies are already sponsoring with donations of $100 of
        more to make sure the above projects are maintained and evolved!
      </p>
      <div className="sponsors">
        <a href="https://mendix.com/">
          <img
            src="https://mobx.js.org/assets/mendix-logo.png"
            align="center"
            width="100"
            title="Mendix"
            alt="Mendix"
          />
        </a>
        <a href="https://coinbase.com/">
          <img
            src="https://mobx.js.org/assets/coinbase.jpeg"
            align="center"
            width="100"
            title="Coinbase"
            alt="Coinbase"
          />
        </a>
        <a href="https://opensource.facebook.com/">
          <img
            src="https://mobx.js.org/assets/fbos.jpeg"
            align="center"
            width="100"
            title="Facebook Open Source"
            alt="Facebook Open Source"
          />
        </a>
        <a href="https://www.canva.com/">
          <img
            src="https://mobx.js.org/assets/canva.png"
            align="center"
            width="100"
            title="Canva"
            alt="Canva"
          />
        </a>
        <a href="https://www.algolia.com/">
          <img
            src="https://mobx.js.org/assets/algolia.jpg"
            align="center"
            width="100"
            title="Algolia"
            alt="Algolia"
          />
        </a>
        <a href="https://www.guilded.gg/">
          <img
            src="https://mobx.js.org/assets/guilded.jpg"
            align="center"
            width="100"
            title="Guilded"
            alt="Guilded"
          />
        </a>
        <a href="http://auctionfrontier.com/">
          <img
            src="https://mobx.js.org/assets/auctionfrontier.jpeg"
            align="center"
            width="100"
            title="Auction Frontier"
            alt="Auction Frontier"
          />
        </a>
        <a href="https://mantro.net/jobs/warlock">
          <img
            src="https://mobx.js.org/assets/mantro.png"
            align="center"
            width="100"
            title="mantro GmbH"
            alt="mantro GmbH"
          />
        </a>
        <a href="https://talentplot.com/">
          <img
            src="https://mobx.js.org/assets/talentplot.png"
            align="center"
            width="100"
            title="talentplot"
            alt="talentplot"
          />
        </a>
        <a href="https://www.codefirst.co.uk/">
          <img
            src="https://mobx.js.org/assets/codefirst.png"
            align="center"
            width="100"
            title="CodeFirst"
            alt="CodeFirst"
          />
        </a>
        <a href="https://blokt.com/">
          <img
            src="https://mobx.js.org/assets/blokt.jpg"
            align="center"
            width="100"
            title="Blokt"
            alt="Blokt"
          />
        </a>
        <a href="https://www.faire.com/">
          <img
            src="https://mobx.js.org/assets/faire.jpg"
            align="center"
            width="100"
            title="Faire"
            alt="Faire"
          />
        </a>
      </div>
    </section>
  </Layout>
)

export default IndexPage
